<template>
  <div
    class="
      h-screen
      w-screen
      bg-black
      bg-opacity-10
      fixed
      top-0
      flex
      justify-center
      items-center
      z-common-loading
    ">
    <img
      src=
        "~/assets/images/loading.gif"
    >
  </div>
</template>
